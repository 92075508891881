<template>
  <div class="doctor" v-if="doctor && doctor.id">
    <el-tabs v-model="activeName">
      <el-tab-pane label="Main info" name="commonInfo">
        <div class="row">
          <div class="col-md-6">
            <DoctorPhoto @get-doctor-event="getDoctorEvent"/>
            <SinglePageConnection :fullItem="doctor" @update="save()"/>
            <SinglePageNameInfo :fullItem="doctor" type="doctor"/>
            <DoctorFlags/>
            <DoctorConsultation/>
          </div>
          <div class="col-md-6">
            <DoctorsClinic/>
            <DoctorStatistics/>
            <DoctorStartOfWork/>
            <DoctorWithBookimed />
            <DoctorSocials />
            <DoctorSpecialities/>
            <DoctorLanguages/>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Certificate/Accreditation" name="certificate">
        <DoctorCertificate :doctor="doctor" @get-doctor-event="getDoctorEvent" @update="save()"/>
      </el-tab-pane>
      <el-tab-pane v-for="domain in domains"
                   :key="domain.language"
                   :label="tabLabel(domain.language)"
                   :name="tabLabel(domain.language)">
        <card v-for="doctorAlias in doctorAliasTexts"
              :title="doctorAlias.alias"
              :key="doctorAlias.alias">
          <base-input v-if="doctorAlias.inputType === 'text'">
            <textarea rows="4" cols="80"
                      class="form-control"
                      :placeholder="doctorAlias.alias"
                      v-model="doctorAlias.text[domain.language]"
            ></textarea>
          </base-input>
          <ckeditor v-if="doctorAlias.inputType === 'wysiwyg' && activeName === tabLabel(domain.language)"
                    :config="{ allowedContent: true }"
                    v-model="doctorAlias.text[domain.language]">
          </ckeditor>
        </card>
      </el-tab-pane>
      <div class="row">
        <div class="col-12">
          <base-button type="primary" @click="save()" v-if="activeName !== 'certificate'">Update doctor</base-button>
        </div>
      </div>
    </el-tabs>
  </div>
</template>

<script>
import DoctorPhoto from '@/components/Doctors/DoctorPhoto';
import SinglePageConnection from '@/components/SinglePage/SinglePageConnection';
import SinglePageNameInfo from '@/components/SinglePage/SinglePageNameInfo';
import DoctorFlags from '@/components/Doctors/DoctorFlags';
import DoctorConsultation from '@/components/Doctors/DoctorConsultation';
import DoctorsClinic from '@/components/Doctors/DoctorsClinic';
import DoctorStatistics from '@/components/Doctors/DoctorStatistics';
import DoctorStartOfWork from '@/components/Doctors/DoctorStartOfWork';
import DoctorWithBookimed from '@/components/Doctors/DoctorWithBookimed';
import DoctorSocials from '@/components/Doctors/DoctorSocials';
import DoctorSpecialities from '@/components/Doctors/DoctorSpecialities';
import DoctorLanguages from '@/components/Doctors/DoctorLanguages';
import DoctorCertificate from '@/components/Doctors/DoctorCertificate';
import { mapActions, mapState, mapMutations } from "vuex";

  export default {
    props: ['doctorId'],
    components: {
      DoctorPhoto,
      SinglePageConnection,
      SinglePageNameInfo,
      DoctorFlags,
      DoctorConsultation,
      DoctorsClinic,
      DoctorStatistics,
      DoctorStartOfWork,
      DoctorWithBookimed,
      DoctorSocials,
      DoctorSpecialities,
      DoctorLanguages,
      DoctorCertificate,
    },
    data: () => ({
      activeName: 'commonInfo',
      doctorAliasTexts: [],
    }),
    computed: {
      ...mapState('cmsApiCatalogs', ['domains']),
      ...mapState('doctors', ['doctor', 'defaultDoctorAliasTexts']),
    },
    created() {
      this.getDoctorEvent();
    },
    beforeDestroy() {
      this.SET_DATA({ type: 'doctor', data: {} });
    },
    methods: {
      ...mapActions('doctors', ['getDoctor', 'updateDoctor']),
      ...mapMutations('doctors', ['SET_DATA']),
      async getDoctorEvent() {
        this.$store.dispatch('setLoader', true);

        await this.getDoctor(this.doctorId);
        this.doctorAliasTexts = this.defaultDoctorAliasTexts.map(defaultText => {
          let findingDoctorText = this.doctor.doctor_texts.find(doctorText => doctorText.alias === defaultText.alias);
          return {
            ...defaultText,
            text: findingDoctorText ? findingDoctorText.text : {},
          };
        });

        this.doctor.doctor_texts = this.doctorAliasTexts;
        this.$store.dispatch('setLoader', false);
      },
      save() {
        this.$store.dispatch('setLoader', true);
        const name = {};

        if (this.doctor.name && Object.keys(this.doctor.name).length) {
          Object.keys(this.doctor.name).forEach(lang => {
            if (this.doctor.name[lang]) {
              name[lang] = this.doctor.name[lang];
            }
          });
        }

        return this.updateDoctor({ data: { ...this.doctor, name } })
          .then(() => this.$notify({ type: 'success', title: 'Saved' }))
          .catch(() => this.$notify({ type: 'warning', title: 'Save Failed' }))
          .finally(() => this.$store.dispatch('setLoader', false));
      },
      tabLabel(language) {
        return `Text ${language.toUpperCase()}`;
      },
    }
  }
</script>
