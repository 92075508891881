<template>
  <card title="Doctor specialities and degree">
    <el-select v-model="doctor.medical_speciality_ids"
               placeholder="Doctor specialities"
               no-data-text="No data"
               no-match-text="No match"
               filterable
               reserve-keyword
               multiple
               value-key="id">
      <el-option v-for="item in doctorSpecialities"
                 :key="item.id"
                 :label="item.name.en"
                 :value="item.id">
      </el-option>
    </el-select>
    <el-select v-model="doctor.degree"
               placeholder="Doctor degree"
               no-data-text="No data"
               no-match-text="No match"
               filterable
               value-key="en">
      <el-option v-for="item in doctorDegrees"
                 :key="item.en"
                 :label="item.en"
                 :value="item">
      </el-option>
    </el-select>
  </card>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  computed: {
    ...mapState('doctors', ['doctor', 'doctorSpecialities', 'doctorDegrees']),
  },
  created() {
    this.getDoctorSpecialities();
    this.getDoctorDegrees();
  },
  methods: {
    ...mapActions('doctors', ['getDoctorSpecialities', 'getDoctorDegrees']),
  },
}
</script>
