<template>
  <card title="Doctor's certificates">
    <div class="row">
      <div class="col-12">
        <div class="doctor-certificate__form">
          <div class="doctor-certificate__form-img-wrap">
            <div class="doctor-certificate__form-img">
              <img v-if="file"
                   class="doctor-certificate__form-img__selected"
                   :src="file.preview">
              <div v-else class="doctor-certificate__form-img__empty">
                <img src="@/assets/imgs/select-photo.svg">
                <div>Drop photo to instantly download</div>
              </div>
            </div>
            <button @click="$refs['doctor-certificate'].click()">Select file</button>
            <input type="file" ref="doctor-certificate" @change="handleFileUpload" style="position: absolute;left: -5000px;"/>
          </div>
          <div class="doctor-certificate__form-input">
            <base-input label="Title"
                        v-model="name"
                        placeholder="Title">
            </base-input>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <base-button type="primary" :disabled="!file || !name" @click="save()">Update certificate</base-button>
      </div>
    </div>
    <div class="row" v-if="doctor.certificates && doctor.certificates.length">
      <div class="col-12">
        <div class="doctor-certificate__list">
          <div class="doctor-certificate__list-item" v-for="(certificate, index) in doctor.certificates" :key="certificate.name">
            <a :href="certificate.url" target="_blank">
              <img :src="certificate.url" alt="">
            </a>
            <span>{{ certificate.name }}</span>
            <button type="button" class="close" aria-label="Close" @click="deleteCertificate(index)">
                  <span aria-hidden="true">
                    <i class="tim-icons icon-simple-remove"></i>
                  </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </card>
</template>

<script>
import resizeImage from '@/plugins/resize';
import APICmsCatalogs from '@/api/modules/cms-api-catalogs';
import { mapState } from 'vuex';

export default {
  data: () => ({
    file: null,
    name: null,
  }),
  computed: {
    ...mapState('doctors', ['doctor']),
  },
  methods: {
    deleteCertificate(index) {
      this.doctor.certificates.splice(index, 1);
      this.$emit('update');
    },
    handleFileUpload() {
      const file = this.$refs['doctor-certificate'].files[0];

      if (file) {
        if (file.size > 8e6) {
          alert('Max size - 8 MB');
          return;
        }

        if (file.type.match(/image.*/)) {
          resizeImage({ file, maxSize: 1200 }).then((resizedImage) => {
            if (resizedImage) {
              this.file = resizedImage;
            }
          });
        } else {
          alert('Select image');
        }
      }
    },
    save() {
      this.$store.dispatch('setLoader', true);
      return APICmsCatalogs.uploadDoctorCertificate({ file: this.file.file, doctorId: this.doctor.id, name: this.name })
        .then(() => {
          this.file = null;
          this.name = null;
          this.$notify({ type: 'success', title: 'Saved' });
          this.$emit('get-doctor-event');
        }).catch(() => this.$notify({ type: 'warning', title: 'Failed' })).finally(() => this.$store.dispatch('setLoader', false));
    },
  },
}
</script>

<style lang="scss">
.doctor-certificate{
  &__list{
    margin-top: 16px;
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    &-item{
      width: calc(50% - 8px);
      background: #FFFFFF;
      border: 1px solid #EAECF0;
      border-radius: 12px;
      padding: 12px;
      img{
        margin-right: 16px;
        width: 80px;
        height: 80px;
        object-fit: cover;
      }
      span{
        font-size: 18px;
      }
    }
  }
  &__form{
    margin-bottom: 16px;
    display: flex;
    &-input{
      flex-grow: 1;
    }
    &-img{
      width: 120px;
      height: 120px;
      border-radius: 8px;
      border: 1px solid #DFE1E5;
      text-align: center;
      font-weight: 400;
      font-size: 13px;
      color: rgba(0, 0, 0, 0.56);
      padding: 14px 9px 0 9px;
      &__selected{
        min-width: 120px;
        height: 120px;
        object-fit: cover;
        border-radius: 8px;
        margin-top: -14px;
        margin-left: -9px;
      }
      &-wrap {
        text-align: center;
        margin-right: 16px;
        button{
          color: #A3CC0E;
          margin-top: 11px;
          background: none;
          border: none;
          font-weight: bold;
        }
      }
      &__empty{
        div{
          margin-top: 5px;
        }
      }
    }
  }
}
</style>
