<template>
  <card title="Consultation" v-if="doctor.consultation">
    <base-input label="Price"
                v-model="doctor.consultation.sum"
                placeholder="Price">
    </base-input>
    <el-select v-model="doctor.consultation.currency_code"
               placeholder="Currency"
               no-data-text="No data"
               no-match-text="No match"
               filterable
               value-key="code">
      <el-option v-for="item in currencies"
                 :key="item.code"
                 :label="item.code"
                 :value="item.code">
      </el-option>
    </el-select>
    <el-checkbox v-model="doctor.consultation.is_consulting_online"
    >
      Is consulting online?
    </el-checkbox>
  </card>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  computed: {
    ...mapState('doctors', ['doctor']),
    ...mapState('data', ['currencies']),
  },
  created() {
    this.getCurrencies();
  },
  methods: {
    ...mapActions('data', ['getCurrencies']),
  }
}
</script>
