<template>
  <card title="Doctor languages">
    <el-select v-model="doctor.languages"
               placeholder="Doctor languages"
               no-data-text="No data"
               no-match-text="No match"
               filterable
               reserve-keyword
               multiple
               value-key="id">
      <el-option v-for="item in languages"
                 :key="item.id"
                 :label="item.name.en"
                 :value="item.id">
      </el-option>
    </el-select>
  </card>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  computed: {
    ...mapState('doctors', ['doctor']),
    ...mapState('data', ['languages']),
  },
  created() {
    this.getLanguages();
  },
  methods: {
    ...mapActions('data', ['getLanguages']),
  }
}
</script>
