<template>
  <card title="Doctor's clinic ID">
    <h4 v-if="doctorsClinicName">
      {{ doctorsClinicName }}
    </h4>
    <base-input label="Doctor's clinic ID"
                v-model="doctor.clinic.id"
                @input="isBtnVisible = true"
                placeholder="Start of work">
    </base-input>
    <button type="button" class="btn btn-primary"
            v-if="isBtnVisible"
            @click="getClinicEvent(true)">Find clinic</button>
  </card>
</template>

<script>
import { mapState } from "vuex";
import APIClinics from '@/api/modules/clinics';

export default {
  data: () => ({
    doctorsClinicName: '',
    initClinicId: null,
    isBtnVisible: false,
  }),
  computed: {
    ...mapState('doctors', ['doctor']),
  },
  created() {
    this.initClinicId = this.doctor.clinic.id;
    this.getClinicEvent();
  },
  methods: {
    getClinicEvent(isOnClick) {
      if (this.doctor.clinic && this.doctor.clinic.id) {
        return APIClinics.getClinic(this.doctor.clinic.id).then((response) => {
          if (response && response.data) {
            if (response.data.clinic) {
              const clinic = response.data.clinic;
              this.doctorsClinicName = clinic.name ? clinic.name.en : '';

              this.doctor.clinic = {
                id: clinic.id,
                city_id: clinic.geo.city_id,
                country_iso: clinic.geo.country_code,
                rating: clinic.rating.rating,
              }
            } else {
              if (isOnClick) {
                this.doctor.clinic.id = this.initClinicId;
                this.doctorsClinicName = '';
                this.$notify({ type: 'warning', title: 'Failed clinic search' });
                this.getClinicEvent();
              }
            }
          }
        });
      }
    },
  }
}
</script>
