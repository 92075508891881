<template>
  <card title="Start of work">
    <base-input label="Start of work"
                v-model="doctor.year_start_work"
                placeholder="Start of work">
    </base-input>
  </card>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState('doctors', ['doctor']),
  },
}
</script>
