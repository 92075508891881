<template>
  <card title="Statistics">
    <base-input v-for="statistic in doctor.statistics"
                :key="statistic.alias"
                :label="statistic.alias"
                v-model="statistic.value"
                :placeholder="statistic.alias">
    </base-input>
  </card>
</template>

<script>
import { mapState } from "vuex";

export default {
  data: () => ({
    statistics: [
      {
        'alias' : 'integral_score',
        'value' : 0
      },
      {
        'alias' : 'operations_performed_count',
        'value' : 0
      },
      {
        'alias' : 'awards_count',
        'value' : 0
      },
      {
        'alias' : 'hirsch_index',
        'value' : 0
      },
      {
        'alias' : 'satisfied_clients',
        'value' : 0
      },
      {
        'alias' : 'review_count',
        'value' : 0
      },
      {
        'alias' : 'review_score',
        'value' : 0
      }
    ]
  }),
  computed: {
    ...mapState('doctors', ['doctor']),
  },
  created() {
    let tmpArray = [];

    this.doctor.statistics = [...this.doctor.statistics, ...this.statistics]
      .filter(item => {
        if (tmpArray.indexOf(item.alias) === -1) {
          tmpArray.push(item.alias);
          return true
        }
        return false;
      });
  }
}
</script>
