<template>
  <card title="Flags">
    <el-checkbox v-for="flag in doctor.flags"
                 :key="flag.name"
                 v-model="flag.value"
    >
      {{ flag.name }}
    </el-checkbox>
  </card>
</template>

<script>
  import { mapState } from "vuex";

  export default {
    computed: {
      ...mapState('doctors', ['doctor']),
    },
  }
</script>
