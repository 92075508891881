<template>
  <card title="Doctor's photo">
    <div class="row">
      <div class="col-md-4 col-sm-12">
        <div class="doctor__photo">
          <img v-if="photo"
               class="doctor__photo"
               :src="photo">
          <img v-else src="@/assets/imgs/select-photo.svg">
        </div>

      </div>
      <div class="col-md-8 col-sm-12">
        <div class="row">
          <div class="col-12">
            <button class="btn btn-secondary mb-15" @click="$refs['doctor-file'].click()">Upload</button>
            <input type="file" ref="doctor-file" @change="handleFileUpload" style="position: absolute;left: -5000px;"/>
          </div>
          <div class="col-12">
            <button class="btn btn-primary mb-15" v-if="file" @click="updateDoctorsPhotoEvent()">Update</button>
          </div>
          <div class="col-12"> <button class="btn btn-danger" v-if="photo" @click="deleteDoctorsPhotoEvent()">Delete</button></div>
        </div>
      </div>
    </div>
  </card>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  data: () => ({
    file: null,
    photo: null,
  }),
  computed: {
    ...mapState('doctors', ['doctor']),
  },
  created() {
    this.photo = `https://img.bookimed.com/doctor/${this.doctor.photo.slice(this.doctor.photo.lastIndexOf('/') + 1)}.jpeg`;
  },
  methods: {
    ...mapActions('doctors', ['updateDoctorsPhoto', 'deleteDoctorsPhoto']),
    handleFileUpload() {
      const file = this.$refs['doctor-file'].files[0];

      if (file) {
        if (file.size > 2e6) {
          alert('The photo size is too large. Please download the photo up to 2MB.');
          return;
        }
        if (file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png') {
          this.file = file;

          let reader  = new FileReader();
          reader.addEventListener("load", function () {
            this.photo = reader.result;
          }.bind(this), false);

          if (this.file) {
            reader.readAsDataURL(this.file);
          }
        } else {
          alert('Make sure your photos have .jpg, .jpeg, file types');
        }
      }
    },
    updateDoctorsPhotoEvent() {
      this.$store.dispatch('setLoader', true);

      return this.updateDoctorsPhoto({ file: this.file, doctorId: this.doctor.id })
        .then(() => {
          this.file = null;
          this.$notify({ type: 'success', title: 'Saved' });
          this.$emit('get-doctor-event');
        }).catch(() => this.$notify({ type: 'warning', title: 'Save Failed' }))
        .finally(() => this.$store.dispatch('setLoader', false));
    },
    deleteDoctorsPhotoEvent() {
      this.$store.dispatch('setLoader', true);

      this.file = null;
      this.photo = null;

      if (this.doctor.photo) {
        return this.deleteDoctorsPhoto(this.doctor.id)
          .then(() => {
            this.$notify({ type: 'success', title: 'Deleted' });
            this.$emit('get-doctor-event');
          }).catch(() => this.$notify({ type: 'warning', title: 'Delete Failed' }))
          .finally(() => this.$store.dispatch('setLoader', false));
      }
    },
  }
}
</script>

<style lang="scss">
  .doctor__photo{
    height: 160px;
    width: 100%;
    object-fit: cover;
  }
</style>
