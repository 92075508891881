<template>
  <card title="With Bookimed since">
    <base-input label="With Bookimed since"
                type="Number"
                v-model="doctor.on_bookimed_board_from"
                placeholder="With Bookimed since">
    </base-input>
  </card>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState('doctors', ['doctor']),
  },
}
</script>
