<template>
  <card title="Doctor socials links">
    <base-input label="LinkedIn"
                v-model="doctor.linked_in_url"
                placeholder="LinkedIn">
    </base-input>
  </card>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState('doctors', ['doctor']),
  },
}
</script>
